/*
1. 统一处理请求异常
2. 异步请求成功的数据不是response, 而是response.data
3. 对post请求参数进行ulencode处理, 而不使用默认的json方式(后台接口不支持)
4. 配置请求超时的时间
5. 通过请求头携带token数据
*/

import axios from 'axios';
import qs from 'qs';
import Md5 from 'md5';

import { getCookie, delCookie } from '@/assets/js/cookie.js';
import { tryHideFullScreenLoading } from '@/assets/js/axiosLoading.js';
import { alertmsg } from '@/assets/js/common';

axios.defaults.timeout = 5000; // 请求超时，适当修改
// axios.defaults.baseURL = "/api";
// 添加请求拦截器，通过这个，我们就可以把Cookie传到后台
var sk = window.sessionStorage.getItem('sk');
axios.interceptors.request.use(
    (config) => {
        console.log(config);
        if (config.method == 'post') {
            config.headers = {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8',
                'Uxb-User-Agent': 'webVersion:v1.0.0',
            };
            if (getCookie('siteid')) {
                config.data.siteid = getCookie('siteid');
            }
            config.data.stime = parseInt(new Date().getTime() / 1000);
            if (config.data.base64Code) {
                var a = config.data.base64Code;
                delete config.data.base64Code;
                config.data.sign = Md5(
                    changeStr(pinjie(objKeySort(config.data)))
                );
                config.data.base64Code = a;
            } else {
                config.data.sign = Md5(
                    changeStr(pinjie(objKeySort(config.data)))
                );
            }
        } else {
            config.headers = {
                'Content-Type': 'application/json; charset=utf-8',
                'Uxb-User-Agent': 'webVersion:v1.0.0',
            };
            if (getCookie('siteid')) {
                config.params.siteid = getCookie('siteid');
            }
            config.params.stime = parseInt(new Date().getTime() / 1000);
            config.params.sign = Md5(
                changeStr(pinjie(objKeySort(config.params)))
            );
        }

        // config.data = qs.stringify(config.data);
        config.data = qs.stringify(config.data, { arrayFormat: 'brackets' })
        function objKeySort (obj) {
            //对象排序
            var newkey = Object.keys(obj).sort();
            var newObj = {};
            for (var i = 0; i < newkey.length; i++) {
                newObj[newkey[i]] = obj[newkey[i]];
            }
            return newObj;
        }

        function pinjie (obj) {
            //对象拼接转成数组
            var sign = [];
            for (var i in obj) {
                if (obj[i] === '' || obj[i] == undefined || obj[i] == null || Array.isArray(obj[i])) {
                    delete obj[i];
                } else {
                    if (sk) {
                        sign.push(sk);
                    } else {
                        sign.push('AWDm5zIME3UOoDLi');
                    }
                    sign.push(i);
                    if (typeof obj[i] == 'object') {
                        sign.push(JSON.stringify(obj[i]));
                    } else {
                        sign.push(obj[i]);
                    }
                    if (sk) {
                        sign.push(sk);
                    } else {
                        sign.push('AWDm5zIME3UOoDLi');
                    }
                }
            }
            return sign;
        }

        function changeStr (arr) {
            //数组转字符串拼接（不要逗号）
            var str = '';
            arr.forEach((item) => {
                str += item;
            });
            return str;
        }

        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    (response) => {
        tryHideFullScreenLoading();
        //token失效，跳转认证中心登录页面
        if (
            response.data.code == -101 ||
            response.data.code == -120 ||
            response.data.code == -401
        ) {
            alertmsg(response.data.message, 'error');
            sessionStorage.removeItem('infos');
            sessionStorage.removeItem('course');
            sessionStorage.removeItem('str_column');
            sessionStorage.removeItem('column_id');
            delCookie('siteid');
            delCookie('infos');
            delCookie('faculty_id');
            if (window.location.href.indexOf('localhost') > -1) {

                window.location.href = 'http://localhost:8080/login/#/';
            } else {
                let hostname = window.location.hostname;

                window.location.href =
                    'http://' + hostname + '/login/#/';
            }
        } else if (response.data.code !== 0) {
            alertmsg(response.data.message, 'error');
        }
        return response;
    },
    (error) => {
        tryHideFullScreenLoading();
        return Promise.reject(error.response);
    }
);
export default axios;
/**
 * fetch 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get (url, data = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: data,
            })
            .then((response) => {
                resolve(response.data);
                // console.log(url,JSON.stringify(data),JSON.stringify(response.data),'get')
            })
            .catch((err) => {
                // console.log(err,url,JSON.stringify(data),'get')
                reject(err);
            });
    });
}

/**
 * post 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post (url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                resolve(response.data);
                // console.log(url,JSON.stringify(data),JSON.stringify(response.data),'post')
            },
            (err) => {
                // console.log(err,url,JSON.stringify(data),'post')
                reject(err);
            }
        );
    });
}
