// 选课路由
export const search = [
	{
		path: '/search',
		name: 'search',
		component: (resolve) => require(['@p/search/search.vue'], resolve),
		meta: {
			type: 'xuanke',
			title: '搜索',
		},
	},
];
