// 真题实战路由
export const pape_do = [
	{
		path: '/pape_do',
		name: '作答过程',
		component: (resolve) =>
			require(['@/views/pages/pratice/pape_do/pape_do'], resolve),
		meta: {
			title: '作答',
		},
	},
	{
		path: '/pape_doRecite',
		name: '作答过程背题模式',
		component: (resolve) =>
			require(['@/views/pages/pratice/pape_do/pape_doRecite'], resolve),
		meta: {
			title: '作答',
		},
	},
];
