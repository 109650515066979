// 题库首页路由
export const tiku = [
	{
		path: '/tiku',
		name: '题库首页',
		component: () => import('@/views/pages/pratice/tiku/tiku.vue'),
		meta: {
			type: '3',
			title: '练题 - 考点刷题',
		},
	},
];
