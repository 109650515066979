// 小米-高校合作路由
export const xcoop = [
	{
		path: '/xcoop',
		name: '高校合作',
		component: (resolve) => require(['@p/xiaomi/xcoop/xcoop.vue'], resolve),
		meta: {
			name: '高校合作',
			title: '高校合作',
			type: 13,
		},
	},
];
