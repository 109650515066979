// 小米-备考学习路由
export const xstudy = [
	{
		path: '/xstudy',
		name: '备考学习',
		component: (resolve) =>
			require(['@p/xiaomi/xstudy/xstudy.vue'], resolve),
		meta: {
			name: '备考学习',
			title: '备考学习',
			type: 15,
		},
	},
];
