// 个人路由
export const user = [
    {
        path: '/user',
        name: '个人',
        component: (resolve) => require(['@p/user/user.vue'], resolve),
        redirect: '/userInfo',
        children: [
            {
                path: '/archives_student',
                name: '个人信息',
                component: (resolve) =>
                    require([
                        '@p/user/components/archives_student.vue',
                    ], resolve),
                meta: {
                    name: '个人信息',
                    title: '个人中心 - 基本信息',
                },
            },
            {
                path: '/credithour',
                name: '我的学分',
                component: (resolve) =>
                    require(['@p/user/components/credithour.vue'], resolve),
                meta: {
                    name: '我的学分',
                    title: '个人中心 - 我的学分',
                },
            },
            {
                path: '/certificate',
                name: '我的证书',
                component: (resolve) =>
                    require(['@p/user/components/certificate.vue'], resolve),
                meta: {
                    name: '我的证书',
                    title: '个人中心 - 我的证书',
                },
            },
            {
                path: '/feedback',
                name: '反馈意见',
                component: (resolve) =>
                    require(['@p/user/components/feedback.vue'], resolve),
                meta: {
                    name: '反馈意见',
                    title: '个人中心 - 反馈建议',
                },
            },
            {
                path: '/account',
                name: '账号设置',
                component: (resolve) =>
                    require(['@p/user/components/account.vue'], resolve),
                meta: {
                    name: '账号设置',
                    title: '个人中心 - 账号设置',
                },
            },
            {
                path: '/userCertificateInfo',
                name: '我的证书详情',
                component: (resolve) =>
                    require([
                        '@p/user/components/com/certificateInfo.vue',
                    ], resolve),
                meta: {
                    name: '我的证书',
                    title: '个人中心 - 我的证书',
                },
            },
            {
                path: '/order',
                name: '我的订单',
                component: (resolve) =>
                    require(['@p/user/components/order.vue'], resolve),
                meta: {
                    name: '我的订单',
                    title: '个人中心 - 我的订单',
                },
            }
        ],
    },
];
