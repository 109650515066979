// 小米路由
import { xhome } from "@p/xiaomi/xhome/assets/xhome.js"; //首页
import { xsign } from "@p/xiaomi/xsign/assets/xsign.js"; //认证报名
import { xcoop } from "@p/xiaomi/xcoop/assets/xcoop.js"; //高校合作
import { xtrain } from "@p/xiaomi/xtrain/assets/xtrain.js"; //企业培训
import { xstudy } from "@p/xiaomi/xstudy/assets/xstudy.js"; //备考学习
import { MyExam } from "@p/xiaomi/MyExam/assets/MyExam.js"; //备考学习


export const xiaomi = [
	...xhome,
  ...xsign,
  ...xcoop,
  ...xtrain,
  ...xstudy,
  ...MyExam
];
