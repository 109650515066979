// 课程路由
export const learn = [
	{
		path: '/learn',
		name: '课程',
		component: (resolve) => require(['@p/learn/learn.vue'], resolve),
		meta: {
			// type: 2,
			// name: '课程',
			title: '课程详情',
		},
	},
];
