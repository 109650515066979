import { Loading } from "element-ui";
// 全局在拦截器中加载loading
let needLoadingRequestCount = 0;
let loading;
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: "加载中，请稍等…",
    spinner: "el-icon-loading",
    background: "rgba(255, 255, 255, 0.4)",
  });
}
function endLoading() {
  // loading.close();
}

function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
}
function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}

export { tryHideFullScreenLoading, showFullScreenLoading };
