// 小米-企业培训路由
export const xtrain = [
	{
		path: '/xtrain',
		name: '企业培训',
		component: (resolve) =>
			require(['@p/xiaomi/xtrain/xtrain.vue'], resolve),
		meta: {
			name: '企业培训',
			title: '企业培训',
			type: 14,
		},
	},
];
