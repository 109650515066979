// 真题实战路由
export const homework = [
  {
    path: "/homework",
    name: "作业作答",
    component: () =>
      import("@/views/pages/homework/homework.vue"),
    meta: {
      type: "study",
      title: '作业'
    },
  },
];
