export const newlearn = [
	{
		path: '/newlearn',
		name: '课程',
		component: (resolve) => require(['@p/newlearn/newlearn.vue'], resolve),
		meta: {
			// type: 2,
			// name: '课程',
			title: '课程详情',
		},
	},
	{
		path: '/noticedetail',
		name: '通知详情',
		component: (resolve) =>
			require(['@p/newlearn/components/noticeDetail.vue'], resolve),
		meta: {
			title: '通知详情',
		},
	},
	{
		path: '/classquiz',
		name: '随堂测验',
		component: (resolve) =>
			require(['@p/newlearn/components/classQuiz.vue'], resolve),
		meta: {
			title: '随堂测验',
		},
	},
];
