// 问卷路由
export const questionnaire = [
	{
		path: '/questionnaire',
		name: '问卷作答',
		component: (resolve) =>
			require(['@/views/pages/questionnaire/questionnaire'], resolve),
		meta: {
			type: 'study',
			title: '问卷',
		},
	},
	{
		path: '/questionnaire_report',
		name: '问卷作答结果',
		component: (resolve) =>
			require([
				'@/views/pages/questionnaire/questionnaire_report',
			], resolve),
		meta: {
			type: 'study',
			title: '问卷',
		},
	},
];
