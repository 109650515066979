import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/reset.scss"


import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/global.scss"
import "./assets/css/public.scss"
import '@/assets/css/skin.css'
import axios from "./api/index";
import { addStorageEvent } from './assets/js/common';
Vue.prototype.$axios = axios;
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;
Vue.use(ElementUI);
Vue.prototype.$addStorageEvent = addStorageEvent;
Vue.config.productionTip = false;
import Print from '@/assets/js/print.js'
Vue.use(Print)
import * as filters from './assets/js/filters'; // global filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

