// 公告路由
export const notice = [
	{
		path: '/notice',
		name: '公告',
		component: (resolve) => require(['@p/notice/notice.vue'], resolve),
		meta: {
			type: 4,
			title: '公告',
		},
	},
	{
		path: '/notice_detail',
		name: '公告详情',
		component: (resolve) =>
			require(['@p/notice/components/notice_detail.vue'], resolve),
		meta: {
			type: 4,
			title: '公告',
		},
	},
];
