// 时间戳转时间，格式自定义 {{ time | formatimes('yyyy-MM-dd hh:mm:ss') }}
function formatimes(date, fmt) {
  let newdate = new Date(date * 1000);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (newdate.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": newdate.getMonth() + 1,
    "d+": newdate.getDate(),
    "h+": newdate.getHours(),
    "m+": newdate.getMinutes(),
    "s+": newdate.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}
function formatimesday(date, fmt) {//首页今天专用
  if(date=='今天'){
    return '今天'
  }else{
  let newdate = new Date(date * 1000);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
        RegExp.$1,
        (newdate.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": newdate.getMonth() + 1,
    "d+": newdate.getDate(),
    "h+": newdate.getHours(),
    "m+": newdate.getMinutes(),
    "s+": newdate.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
  }
}
function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

/**
 * @return {string}
 */
function DecimalDouble(data) {
  if (data !== undefined) {
    let datasize = parseFloat(data) / 1024;
    return `${datasize.toFixed(2)}KB`;
  }
}
// 将阿拉伯数字转换为汉字的算法 {{num | numberfilter}}
function numberfilter(num) {
  const changeNum = [
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九",
  ]; // changeNum[0] = "零"
  const unit = ["", "十", "百"];
  num = parseInt(num);
  const getWan = (temp) => {
    const strArr = temp.toString().split("").reverse();
    let newNum = "";
    for (var i = 0; i < strArr.length; i++) {
      newNum =
        (i == 0 && strArr[i] == 0
          ? ""
          : i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
          ? ""
          : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) +
        newNum;
    }
    return newNum;
  };
  const overWan = Math.floor(num / 100);
  let noWan = num % 100;
  if (noWan.toString().length < 2) noWan = "0" + noWan;
  return overWan ? getWan(overWan) + "百" + getWan(noWan) : getWan(num);
}
function byteConvert(bytes) {
  if (isNaN(bytes)) {
    return '';
  }
  //在这里定义了常用的字节，字节、千字节、兆字节、吉字节、太字节、拍字节、艾字节、Z字节、Y字节
  var symbols = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var exp = Math.floor(Math.log(bytes) / Math.log(2));
  if (exp < 1) {undefined
    exp = 0;
  }
  var i = Math.floor(exp / 10);
  bytes /= Math.pow(2, 10 * i)
  if (bytes.toString().length > bytes.toFixed(2).toString().length) {undefined
    bytes = bytes.toFixed(2);
  }
  return bytes + ' ' + symbols[i];
}
function secondToDate(result) {
  var h = Math.floor(result / 3600) < 10 ? '0'+Math.floor(result / 3600) : Math.floor(result / 3600);
  var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  var s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
  return result = h + ":" + m + ":" + s;
}

export { DecimalDouble, formatimes, numberfilter,byteConvert,formatimesday,secondToDate};
