// 小米-认证报名路由
export const xsign = [
	{
		path: '/xsign',
		name: '认证报名',
		component: (resolve) => require(['@p/xiaomi/xsign/xsign.vue'], resolve),
		meta: {
			name: '认证报名',
			title: '认证报名',
			type: 12,
		},
	},
];
