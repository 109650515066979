export const examRequire = [
  {
    path: "/examRequire",
    name: "课程考试要求",
    component: (resolve) =>
      require(["@p/examRequire/examRequire.vue"], resolve),
    meta: {
      // type: 2,
      // name: '课程',
      title: "考试要求",
    },
  },
  {
    path: "/studentsExam",
    name: "课程作答",
    component: (resolve) =>
      require(["@p/examRequire/studentsExam.vue"], resolve),
    meta: {
      // type: 2,
      // name: '课程',
      title: "考试",
    },
    beforeEnter:(to,from,next)=>{
      from.path=='/ExamResult'?next('/'):next()
    }
  },
  {
    path: "/examReport",
    name: "课程作答报告",
    component: (resolve) => require(["@p/examRequire/examReport.vue"], resolve),
    meta: {
      // type: 2,
      // name: '课程',
      title: "考试报告",
    },
  },
];
