// 小米-首页路由
export const xhome = [
	{
		path: '/xhome',
		name: '首页',
		component: (resolve) => require(['@p/xiaomi/xhome/xhome.vue'], resolve),
		meta: {
			name: '首页',
			title: '首页',
			type: 11,
		},
	},
  {
		path: '/xhomeInfo',
		name: '认证',
		component: (resolve) => require(['@p/xiaomi/xhome/com/xhomeInfo.vue'], resolve),
		meta: {
			name: '认证',
			title: '首页-认证',
			type: 11,
		},
	},
];
