/**
 * 日期格式化
 */
export function dateFormat(date) {
  let format = "yyyy-MM-dd hh:mm:ss";
  if (date != "Invalid Date") {
    var o = {
      "M+": date.getMonth() + 1, //month
      "d+": date.getDate(), //day
      "h+": date.getHours(), //hour
      "m+": date.getMinutes(), //minute
      "s+": date.getSeconds(), //second
      "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
      S: date.getMilliseconds(), //millisecond
    };
    if (/(y+)/.test(format))
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o)
      if (new RegExp("(" + k + ")").test(format))
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return format;
  }
  return "";
}
export function dateTime(ts) {
  let time = new Date(ts);
  let year = time.getFullYear();
  const month = (time.getMonth() + 1).toString().padStart(2, "0");
  const day = time.getDate().toString().padStart(2, "0");
  const hour = time.getHours().toString().padStart(2, "0");
  const minute = time.getMinutes().toString().padStart(2, "0");
  const second = time.getSeconds().toString().padStart(2, "0");

  return (
    year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
  );
}
//2020-07-01
export function newdate(date) {
  var nowdate = new Date(date).toLocaleDateString().replace(/\//g, "-");
  return nowdate;
}
//20200701
export function newdatenumber(date) {
  let year = new Date(date).getFullYear();
  let month =
    new Date(date).getMonth() + 1 < 10
      ? "0" + (new Date(date).getMonth() + 1)
      : new Date(date).getMonth() + 1;
  let day =
    new Date(date).getDate() < 10
      ? "0" + new Date(date).getDate()
      : new Date(date).getDate();
  return year + "" + month + "" + day;
}
//提示框
import { Message } from "element-ui";
export function alertmsg(val, type) {
  // 文字提示，val:提示的文字，type:提示类型，种类参考element-ui的api
  Message({
    showClose: true,
    message: val,
    type: type,
    duration: 2000,
  });
}
// 监听storage改变
export function addStorageEvent(type, key, data) {
  // 创建一个StorageEvent事件
  var newStorageEvent = document.createEvent('StorageEvent');
  if (type === 1) {
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    };
    return storage.setItem(key, data);
  } else {
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    };
    return storage.setItem(key, data);
  }
}