// 小米-我的考试路由
export const MyExam = [
	{
		path: '/MyExam',
		name: '我的考试',
		component: (resolve) => require(['@p/xiaomi/MyExam/MyExam.vue'], resolve),
		meta: {
			name: '我的考试',
			title: '我的考试',
		},
	},
];
export const examTicket = [
	{
		path: '/examTicket',
		name: '准考证',
		component: (resolve) => require(['@p/xiaomi/MyExam/com/examTicket.vue'], resolve),
		meta: {
			name: '准考证',
			title: '准考证',
		},
	},
];

