// 课程通知路由
export const message = [
	{
		path: '/message',
		name: '通知',
		component: (resolve) => require(['@p/message/message.vue'], resolve),
		meta: {
			title: '通知',
		},
	},
];
