// 学习路由
import { getCookie } from '@/assets/js/cookie.js';
let xiaomiFlag = getCookie('siteid') == 52 || getCookie('siteid') == 56;
//小米打开的首页
let a = [
    {
        path: '/',
        name: '首页',
        component: (resolve) => require(['@p/xiaomi/MyExam/MyExam.vue'], resolve),
        redirect: '/MyExam',
        meta: {
            name: '首页',
            title: '首页',
            type: 11,
        },
    },
];
//非小米打开的首页
let b = [
    {
        path: '/',
        name: '学习',
        component: (resolve) => require(['@p/study/study.vue'], resolve),
        redirect: '/mycourse_group',
        meta: {
            type: 2,
            name: '我的课程',
        },
        children: [
            {
                path: '/mycourse_group',
                name: '我的课程',
                component: (resolve) =>
                    require([
                        '@p/study/components/mycourse_group.vue',
                    ], resolve),
                meta: {
                    type: 2,
                    name: '我的课程',
                    title: '学习 - 我的课程',
                },
            },
            // {
            //   path: "/MyExam",
            //   name: "我的考试",
            //   component: (resolve) =>
            //     require(["@p/study/components/MyExam.vue"], resolve),
            //   meta: {
            //     type: 2,
            //     name: "我的考试",
            //     title: "我的考试",
            //   },
            // },
            {
                path: '/learn_record',
                name: '学习记录',
                component: (resolve) =>
                    require(['@p/study/components/learn_record.vue'], resolve),
                meta: {
                    type: 2,
                    name: '学习记录',
                    title: '学习 - 学习记录',
                },
            },
            {
                path: '/live_calendar',
                name: '直播日历',
                component: (resolve) =>
                    require(['@p/study/components/live_calendar.vue'], resolve),
                meta: {
                    type: 2,
                    name: '直播日历',
                    title: '学习 - 直播日历',
                },
            },
            {
                path: '/learn_task',
                name: '学习任务',
                component: (resolve) =>
                    require(['@p/study/components/learn_task.vue'], resolve),
                meta: {
                    type: 2,
                    name: '学习任务',
                    title: '学习 - 学习任务',
                },
            },
            {
                path: '/note',
                name: '读书笔记',
                component: (resolve) =>
                    require(['@p/study/components/note.vue'], resolve),
                meta: {
                    type: 2,
                    name: '读书笔记',
                    title: '学习 - 读书笔记',
                },
            },
            {
                path: '/plan',
                name: '教学计划',
                component: (resolve) =>
                    require(['@p/study/components/plan.vue'], resolve),
                meta: {
                    type: 2,
                    name: '教学计划',
                    title: '学习 - 教学计划',
                },
            },
            {
                path: '/note_add',
                name: '添加读书笔记',
                component: (resolve) =>
                    require(['@p/study/components/com/note_add.vue'], resolve),
                meta: {
                    type: 2,
                    name: '读书笔记',
                    title: '学习 - 读书笔记',
                },
            },
        ],
    },
    {
        path: '/plan_detail',
        name: '学习计划详情',
        component: (resolve) =>
            require(['@p/study/components/com/plan_detail.vue'], resolve),
        meta: {
            // type: 2,
            title: '学习 - 教学计划',
        },
    },
];
export const study = xiaomiFlag ? a : b;
// 小米考试结果
export const ExamResult = [
    {
        path: '/ExamResult',
        name: '认证的考试结果',
        component: (resolve) =>
            require(['@p/study/components/ExamResult.vue'], resolve),
        meta: {
            name: '考试结果',
            title: '个人中心 - 考试结果',
        },
    },
];
