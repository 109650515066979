// 真题实战路由
export const paper_report = [
	{
		path: '/paper_report',
		name: '作答报告',
		component: (resolve) =>
			require([
				'@/views/pages/pratice/paper_report/paper_report',
			], resolve),
		meta: {
			title: '报告',
		},
	},
];
