// 视频直播路由
export const video = [
	{
		path: '/video',
		name: '视频',
		component: (resolve) => require(['@p/video/video'], resolve),
		meta: {
			type: 2,
			name: '视频',
			title: '课程学习',
		},
	},
	{
		path: '/videoplay',
		name: '视频预览',
		component: (resolve) => require(['@p/video/videoplay'], resolve),
		meta: {
			title: '视频预览',
		},
	},
];
