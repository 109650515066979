// 题库类型路由
export const praticeType = [
	{
		path: '/praticeType',
		name: '题库类型',
		component: (resolve) =>
			require(['@p/pratice/praticeType/praticeType.vue'], resolve),
		redirect: '/error_question',
		meta: {
			type: 3,
			name: '题库',
		},
		children: [
			{
				path: '/error_question',
				name: '我的错题',
				component: (resolve) =>
					require([
						'@p/pratice/praticeType/components/error_question.vue',
					], resolve),
				meta: {
					type: 3,
					name: '我的错题',
					title: '练题 - 我的错题',
				},
			},
			{
				path: '/collect_question',
				name: '试题收藏',
				component: (resolve) =>
					require([
						'@p/pratice/praticeType/components/collect_question.vue',
					], resolve),
				meta: {
					type: 3,
					name: '试题收藏',
					title: '练题 - 试题收藏',
				},
			},
			{
				path: '/collect_paper',
				name: '试卷收藏',
				component: (resolve) =>
					require([
						'@p/pratice/praticeType/components/collect_paper.vue',
					], resolve),
				meta: {
					type: 3,
					name: '试卷收藏',
					title: '练题 - 试卷收藏',
				},
			},
			{
				path: '/test_history',
				name: '练习历史',
				component: (resolve) =>
					require([
						'@p/pratice/praticeType/components/test_history.vue',
					], resolve),
				meta: {
					type: 3,
					name: '练习历史',
					title: '练题 - 练习历史',
				},
			},
		],
	},
];
